/*--------------------------
        Modal
---------------------------*/

.modal {
  &-lg,
  &-xl {
    @include breakpoint($large-desktop) {
      max-width: 900px;
    }
  }

  &-dialog {
    min-width: 580px;
  }

  &-content {
    border: none;
    border-radius: 6px;
  }

  &-header {
    border-bottom: none;
    padding: 1.25rem 1.25rem 0;

    .close {
      padding: 0.6rem 1.25rem 0.6rem 0;

      &:focus {
        outline: none;
      }
    }
  }

  &-title {
    @include font-title(var(--header-font), 400, var(--font-h3), var(--header-color));
    line-height: 19px;
    padding: 0rem;
  }

  &-body {
    padding: 1rem;

    .flex-column {
      @include breakpoint($medium-screens) {
        flex-direction: column !important;
      }
    }

    .scenario-container {
      min-width: 210px;
      padding-left: $spacer - 0.7;
      padding-bottom: $spacer - 0.75;
    }

    h2 {
      &:first-child {
        padding: 0 5px 4px;
      }
      padding: 30px 5px 4px;
    }

    h3 {
      padding: 0 5px 0;
    }

    .foutmelding {
      padding: 0;
      width: 100%;
    }

    /* Wordt misschien een grid, dan weghalen */
    .table-verzekeringen {
      tbody {
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        color: var(--darkest-grey);
        padding: 0 2.3rem;
      }
    }

    .border-container {
      .calculated-border {
        width: 85%;
        margin: 0 5px;

        @include breakpoint($medium-screens) {
          width: 90%;
        }
      }

      .calculated-icon {
        @include flex();
        justify-content: center;
        width: 20px;
        margin-left: 2px;
      }
    }

    .input-group {
      .input-group {
        .input-group {
          .col-form-label {
            padding-top: 0;
          }
        }
      }
    }

    .input-to-right-with-button {
      .input-group {
        width: 193px;

        @include breakpoint($medium-screens) {
          width: auto;
        }
      }
    }

    .input-to-right {
      .input-group {
        justify-content: flex-end;
        width: 151px;

        @include breakpoint($medium-screens) {
          width: 177px;
        }
      }
    }

    .no-height {
      height: auto;
    }
  }

  &-footer {
    background: var(--light-grey);
    padding: 10px 1.25rem;
    border-top: none;
  }

  &-eigenwoningschuld {
    .border-container {
      .calculated-border {
        width: 88%;
        margin: 3px 5px;
      }
    }

    .three-column-layout {
      .col-form-label {
        padding-top: 3px !important;
      }

      .btn-outline-secondary {
        margin-top: -3px;
      }

      div {
        &:nth-child(2) {
          .input-group {
            width: 80px;
          }
        }
      }

      .totaal-container {
        width: 7px;
      }
    }

    .totaal {
      .input-group {
        .input-group {
          justify-content: flex-end;
          width: 356px;

          @include breakpoint($medium-screens) {
            width: auto;
          }
        }
      }
    }

    .input-right-allign {
      .input-group {
        justify-content: flex-end;
        width: 326px;

        @include breakpoint($medium-screens) {
          width: auto;
        }
      }
      div {
        .totaal-container {
          width: 100px;
        }
      }
    }
  }

  .button-container {
    margin: 20px 0;
  }

  /* special modal with cards */
  &-with-cards {
    .flex-row .all-cards-same-size {
      flex: 1 1 auto;
    }
    .modal-body {
      padding: 1rem !important;
      h2 {
        &:first-child {
          padding: 0 0 4px;
        }
      }

      .input-group {
        .input-group {
          .input-group {
            .col-form-label {
              padding-top: 3px;
            }
          }
        }
      }

      .border-container {
        .calculated-border {
          width: 95%;
          margin: 3px 5px 5px;
        }
      }

      .full-width-card .card {
        min-width: 100% !important;
        max-width: 100% !important;
      }

      .hidden-flexbox {
        .card-adviesbox {
          border: none !important;
        }
      }

      :not(.hidden-flexbox) .card-adviesbox {
        min-width: 420px;
        max-width: 420px;
        border: 1px solid var(--mid-grey);
      }
    }
  }
}
